<template>
  <div class="card questions">
    <div class="card-header">
      <h3 class="card-title">{{ $t('page.title.subscription_offer_details') }}: <span
          v-if="offer">{{ offer.name }}</span></h3>
      <div class="float-right">
        <router-link :to="{'name':'subscription-offers-list'}">
          <b-button size="sm" variant="outline-primary">{{ $t('btn.back') }}</b-button>
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <b-card v-if="offer">
            <b-card-header><b>{{ $t('page.general_information') }}</b></b-card-header>
            <b-card-body>
              <label>Id</label>
              <p>{{ offer.id }}</p>

              <label>{{ $t('page.offer.name') }}</label>
              <p>
                <input v-model="formEdit.name" v-if="isEdit" class="form-control">
                <span v-else>{{ offer.name }}</span>
              </p>

              <label>{{ $t('page.offer.price') }}</label>
              <p>
                <input v-model="formEdit.price" v-if="isEdit" class="form-control" type="number" step="0.01">
                <span v-else>{{ offer.price }} PLN</span>
              </p>

              <label>{{ $t('page.offer.level') }}</label>
              <p>
                <span>{{ offer.level }}</span>
              </p>

              <label>{{ $t('page.offer.accessToLessons') }}</label>
              <p>
                <span>{{ offer.accessToLessons ? 'Tak' : 'Nie' }}</span>
              </p>

              <label>{{ $t('page.offer.accessToScores') }}</label>
              <p>
                <span>{{ offer.accessToScores ? 'Tak' : 'Nie' }}</span>
              </p>

              <label>{{ $t('page.offer.accessToScoreVideos') }}</label>
              <p>
                <span>{{ offer.accessToScoreVideos ? 'Tak' : 'Nie' }}</span>
              </p>
              <div v-if="offer.id == '3f09f481-4ecb-42f3-b95e-5b630a21c0bd'">


                <label>Zakup VIP</label>
                <b-button v-if="offer.purchaseDisabled" class="btn-sm ml-3 pt-0 pb-0" variant="outline-success" @click="enable">Włącz</b-button>
                <b-button v-if="!offer.purchaseDisabled" class="btn-sm ml-3 pt-0 pb-0" variant="outline-danger" @click="disable">Wyłącz</b-button>
                <p>
                  <span v-if="!offer.purchaseDisabled" class="badge badge-success">Włączony</span>
                  <span v-if="offer.purchaseDisabled" class="badge badge-danger">Wyłączony</span>
                </p>

                <label>Limit zakupów VIP</label>
                <p>
                  <span v-if="offer.canPurchaseVip" class="badge badge-success">Nie przekroczony</span>
                  <span v-if="!offer.canPurchaseVip" class="badge badge-danger">Przekroczony</span>

                </p>
              </div>
            </b-card-body>

          </b-card>
        </div>


      </div>
      <div class="buttons float-right">
        <b-button v-if="!isEdit" type="button" @click="startEdit()" variant="success" class="mr-1">{{
            $t('actions.edit')
          }}
        </b-button>
        <b-button v-if="isEdit" type="button" @click="changeData()" variant="success" class="mr-1">{{
            $t('actions.save')
          }}
        </b-button>
        <b-button v-if="isEdit" type="button" @click="isEdit = false" variant="danger" class="mr-1">
          {{ $t('actions.cancel') }}
        </b-button>

      </div>
    </div>
  </div>
</template>

<script>

import alertify from 'alertifyjs';
import axios from "axios";

export default {
  props: ['apiKey'],
  components: {},
  data() {
    return {
      offerId: null,
      formEdit: {},
      isEdit: false,
    };
  },
  mounted() {
    this.getOffer();
  },
  computed: {
    offer: function () {
      return this.$store.getters["subscriptionOfferStore/offer"];
    },
  },
  beforeDestroy() {
    this.$store.commit('subscriptionOfferStore/setOffer', null);
  },
  created() {
    this.offerId = this.$route.params.id;
  },
  methods: {
    startEdit() {
      this.formEdit = {name: this.offer.name, price: this.offer.price};
      this.isEdit = true;
    },
    getOffer() {
      this.$store.dispatch('subscriptionOfferStore/getOffer', this.offerId);
    },
    changeData() {
      const url = '/subscription-offer/' + this.offerId + '/edit';
      this.formEdit.price = parseFloat(this.formEdit.price);
      axios.post(url, this.formEdit)
          .then(() => {
            alertify.success('Oferta została zmieniona');
            this.getOffer();
            this.isEdit = false;
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
    enable() {
      const url = '/subscription-offer/' + this.offerId + '/enable';
      axios.post(url, {})
          .then(() => {
            alertify.success('Zakup VIP został włączony');
            this.getOffer();
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
    disable() {
      const url = '/subscription-offer/' + this.offerId + '/disable';
      axios.post(url, {})
          .then(() => {
            alertify.success('Zakup VIP został wyłączony');
            this.getOffer();
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
  },
  watch: {
    $route() {
    }
  }
}
</script>

